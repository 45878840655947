import { ImagesType } from '~/types/solepro/PageBuilder';
import { removeEmptyValuesFromObject } from './functions';
import { SLIDER_CLASSES } from '../constants/solepro/pageBuilderCustomClasses';

const backgroundImages = (imagesParam: String) => {
  let images: ImagesType = {};
  const classes = [];
  try {
    // @ts-ignore
    // eslint-disable-next-line no-useless-escape
    images = JSON.parse(imagesParam.replaceAll('//', '\/\/').replaceAll('\\', '') as string) as ImagesType;
    let mdPrefix = '';
    if (images.mobile_image) {
      classes.push(`bg-[url('${images.mobile_image}')]`);
      mdPrefix = 'md:';
    }
    if (images.desktop_image) {
      classes.push(`${mdPrefix}bg-[url('${images.desktop_image}')]`);
    }
  } catch { /** */ }
  return classes;
};

const getBackgroundClasses = (dataset) => ((dataset.backgroundType == 'image' || (!dataset.backgroundType && dataset.backgroundImages)) ? backgroundImages(dataset.backgroundImages as String) : []); /** The video is managed differently */

const RowClasses = (node, dataset) => {
  const children = Array.from(node.children || []).filter((el: HTMLElement)=>el.dataset.contentType != 'text' && el.dataset.contentType != 'column-group')
  const innerClasses = children.length > 0 ? [...node.children[0].classList] : []; /** Do not remove object assignation. it will break the children composition */
  return [
    ...innerClasses,
    ...((dataset.backgroundType == 'image') ? backgroundImages(node.dataset.backgroundImages as String) : []), /** The video is managed differently */
  ];
};

const ColumnGroupClasses = (node, dataset) => {
  return [
  /* ...Array.from(node.classList), */
  ...getBackgroundClasses(dataset),
]};
const ColumnLineClasses = ( node, dataset ) => {
  const gapClasses = Array.from(node.parentElement.classList).filter((el:string)=>el.match(/gap-/g))
  return [
    /* ...Array.from(node.classList), */
    /** mobile wrap/no-wrap managed in /assets/base/pagebuilder/__columns.scss */
    'md:flex-nowrap',
    ...(gapClasses.length ? gapClasses : ['gap-5']),
  ]
};

const ColumnClasses = (node, dataset) => { 
  return [
    'basis-full',
    'md:basis-auto',
    ...node.classList,
    ...getBackgroundClasses(dataset),
  ]
};

const BannerClasses = (node, dataset) => [
  /* ...Array.from(node.classList), */
  ...getBackgroundClasses(dataset.bannerDatas),
];

const SlideClasses = (node, dataset) => [
  /* ...Array.from(node.classList), */
  ...getBackgroundClasses(dataset.bannerDatas),
];

const ButtonItemClasses = (node, dataset) => {
  if (node.children.length > 0) {
    Array.from(node.classList).forEach( c=> {
      node.children[0].classList.add(c)
    })
  }
  return []
}

export const getClasses = (node, dataset) => {
  switch (dataset.contentType) {
    case 'row': return RowClasses(node, dataset);
    case 'column-group': return ColumnGroupClasses(node, dataset);
    case 'column-line': return ColumnLineClasses( node, dataset );
    case 'column': return ColumnClasses(node, dataset);
    case 'banner': return BannerClasses(node, dataset);
    case 'slide': return SlideClasses(node, dataset);
    case 'button-item': return ButtonItemClasses(node, dataset);
    default: return [];
  }
};

export const getStyles = (node/* , dataset */) => { 
  return {
    ...removeEmptyValuesFromObject({ ...node.style }),
    isHidden: node.style?.display === 'none',
  }
};

export const getPageBuilderStyleNode = (node, isChildElement) => {
  try {
    if (!isChildElement) {
      /** Magento PB puts all styles only on the root element */
      return node.head.querySelector('style').innerHTML.replaceAll('#html-body', 'body');
    } if (node.dataset.contentType == 'block') {
      /** For the Block content styles are managed inside the block */
      return node.children[0].querySelector('style').innerHTML.replaceAll('#html-body', 'body');
    }
  } catch {
    /** no styles on element */
  }
  return null;
};
