
import { DURATION_LABEL_KEYS as DLK } from "../constants/layout";
import { ADD_TO_CART_TIPO_ABBONAMENTO_PARAMS, BUNDLE_TYPENAME, CONFIGURABLE_TYPENAME, DURATION_MATCHING, TIPO_RINNOVO_AUTOMATICO } from "../constants/product";
const DURATION_LABEL_KEYS = Object.assign(DLK)
export const composeBundleInfos = (product, tipo_value_index, consegna_value_index, modulesSkus) => {
  let datas = [];
  product.items.forEach(bundleOption => {
    bundleOption.options.forEach(option => {
      if (consegna_value_index) {
        const tipoConsegnaConfigOption = option.product.configurable_options ? option.product.configurable_options.find(el => el.attribute_code == 'tipo_consegna') : null;
        //console.log(tipoConsegnaConfigOption.values.map(el=>el.value_index))
        if (tipoConsegnaConfigOption) {
          let variants = {}
          let configOptions = {}
          variants[bundleOption.option_id] = { selection_id: option.id, ...option.product.variants.find(variant => (!tipo_value_index || variant.product.tipo_abbonamento == tipo_value_index) && variant.product.tipo_consegna == consegna_value_index) };
          configOptions[bundleOption.option_id] = tipoConsegnaConfigOption;
          datas.push({
            option_id: bundleOption.option_id,
            selectedOptionIds: [option.id],
            variants,
            configOptions
          })
        }
      }
      if (tipo_value_index) {
        const tipoAbbonamentoConfigOption = option.product.configurable_options ? option.product.configurable_options.find(el => el.attribute_code == 'tipo_abbonamento') : null;
        if (tipoAbbonamentoConfigOption) {
          let variants = {};
          let configOptions = {};
          configOptions[bundleOption.option_id] = tipoAbbonamentoConfigOption;
          variants[bundleOption.option_id] = { selection_id: option.id, ...option.product.variants.find(variant => variant.product.tipo_abbonamento == tipo_value_index) };
          datas.push({
            option_id: bundleOption.option_id,
            selectedOptionIds: [option.id],
            variants,
            configOptions
          })
        }
      }
    })
    /** Getting options for choosen modules */
    if (modulesSkus && modulesSkus.length > 0) {
      const modulesUids = bundleOption.options.filter(el => modulesSkus.includes(el.product.sku)).map(el => el.id);
      if (modulesUids.length > 0) {
        datas.push({
          option_id: bundleOption.option_id,
          selectedOptionIds: modulesUids
        })
      }
    }
  })

  return datas;
}

export const composeQueryString = (queryStringAsObj: { [key: string]: string }): string => Object.keys(harvestQueryString(queryStringAsObj)).length !== 0 ? `&${new URLSearchParams(harvestQueryString(queryStringAsObj)).toString()}` : "";

const harvestQueryString = (queryStringAsObj: { [key: string]: string }) => {
  /** @NOTE Avoid unwanted params to be transported inside the checkout. */
  const queryParams = { ...queryStringAsObj }

  /** Array of properties to delete */
  const harvestPropertiesMap = [
    'sku',
    'moduli',
    'tipoAbbonamento',
    'tipoConsegna'
  ];
  harvestPropertiesMap.forEach(property => delete queryParams[property]);
  return queryParams;
}


export const getSecondLine = (i18n, cartItem, p, variantSku, paymentFrequency = null) => {
  const variant = variantSku ? p.variants.find(el => el.product.sku == variantSku) : null;
  let labels = [];
  if (variant && !!variant.product.natura) {
    if(p.configurable_options.find(el => el.attribute_code == 'natura')) labels = [{
      title: 'Formato',
      content: i18n.t(p.configurable_options.find(el => el.attribute_code == 'natura').values.find(el => el.value_index == variant.product.natura).label.replace('Formato ', ''))
    }]
  } else if (cartItem.bundle_options && cartItem.bundle_options.length > 0) {
    const mods = cartItem.bundle_options.map((opt, index) => opt.values.map(e => e.label).join('; '))
    labels = [{
      title: `Prodotti inclusi(${mods.length})`,
      content: mods.join('; ')
    }]
  } else if (variant) {
    labels = [{
      content: variant.product.name.replace(cartItem.product.name + ' - ', '').replace(cartItem.product.name, '')//rimozione stilistica della ripetizione del prodotto principale
    }]
  }
  if (paymentFrequency) {
    labels.push({
      title: 'Periodicità pagamento',
      content: i18n.t(`payment_frequency_${DURATION_MATCHING[paymentFrequency]}`)
    })
    if (variant) {
      labels = labels.filter(el => el.title || (!el.title && el.content.toLowerCase().trim() !== i18n.t(`payment_frequency_${DURATION_MATCHING[paymentFrequency]}`).toLowerCase().trim()))
    }
  }
  return labels;
}

const getDiscountedLabel = (product, i18n, $fc) => {
  if (product.price_range.maximum_price.final_price.value < product.price_range.maximum_price.regular_price.value) {
    const percentage = 100 - Math.floor(100 * product.price_range.maximum_price.final_price.value / product.price_range.maximum_price.regular_price.value)
    return i18n.t("{price} {percentage} {discountedPrice}", {
      price: $fc(product.price_range.maximum_price.regular_price.value),
      discountedPrice: $fc(product.price_range.maximum_price.final_price.value),
      percentage
    }) as String
  } else {
    return $fc(product.price_range.maximum_price.final_price.value)
  }
}

const getNormalPrice = (product, i18n, $fc, variant, span) => {
  if (span) {
    return $fc(product.prices[span] > 0 ? product.prices[span] : (variant ? getDiscountedLabel(variant, i18n, $fc) : '--'))
  } else if (variant) {
    return getDiscountedLabel(variant, i18n, $fc)
  } else if (product.prices && Object.keys(product.prices).length > 0) {
    return $fc(product.prices[Object.keys(product.prices)[0]])
  }
}

const getPriceByConfig = (labels, index, i18n, $fc, product, span, variant) => {
  if (product.promotional[span]) {

    labels[index] = (product.promotional[span].price < product.prices[span]) ?
      i18n.tc(`cart ${index == 0 ? 'first' : ''} {durationQty} {duration} at {price} {discountedPrice}`, product.promotional[span].duration, {
        duration: i18n.tc(DURATION_LABEL_KEYS[span], product.promotional[span].duration),
        durationQty: product.promotional[span].duration,
        price: $fc(product.prices[span]),
        discountedPrice: $fc(product.promotional[span].price)
      })
      : i18n.tc(`cart ${index == 0 ? 'first' : ''} {durationQty} {duration} at {discountedPrice}`, product.promotional[span].duration, {
        duration: i18n.tc(DURATION_LABEL_KEYS[span], product.promotional[span].duration),
        durationQty: product.promotional[span].duration,
        discountedPrice: $fc(product.promotional[span].price)
      })

    labels[index + 1] = i18n.tc(`${index==0 ? 'first ':''}cart {price} / each {durationQty} {duration}`, 1, {
      price: $fc(product.discountedPrices[span] ? product.discountedPrices[span] : product.prices[span]),
      durationQty: 1,
      duration: i18n.tc(DURATION_LABEL_KEYS[span], 1),
    })

  } else if (product.discountedPrices[span]) {
    labels[index] = i18n.tc(`${index==0 ? 'first ':''}cart {price} / each {durationQty} {duration}`, 1, {
      price: $fc(product.discountedPrices[span]),
      durationQty: 1,
      duration: i18n.tc(DURATION_LABEL_KEYS[span], 1)
    }) as String
  } else {
    labels[index] = span ? i18n.tc(`${index==0 ? 'first ':''}cart {price} / each {durationQty} {duration}`, 1, {
      price: $fc(product.prices[span] > 0 ? product.prices[span] : (variant ? variant.price_range.maximum_price.final_price.value : '--')),
      durationQty: 1,
      duration: i18n.tc(DURATION_LABEL_KEYS[span], 1)
    }) as String
      : getNormalPrice(product, i18n, $fc, variant, span)

  }
  return labels
}
/**
 * @TODO Questa logica è orrenda, lo so, la fretta ci rende tutti un po' come dott.Frankenstein.
 * La devo rifare assolutamente da capo.
 */
export const getLabels = (i18n, $fc, cartItem, product, span, variantSku) => {
  let labels = []
  const isCurrentPlanTrial = product.isTrial && (product.trialDurationSpan == span || !product.paymentFrequencies.includes(product.trialDurationSpan) || product.forceShowTrialInEachPriceSection);
  if (isCurrentPlanTrial) {
    labels[0] = i18n.tc('{durationQty} {duration} of trial at {trialPrice}', product.trialPrice, {
      durationQty: product.trialDuration,
      duration: i18n.tc(DURATION_LABEL_KEYS[product.trialDurationSpan], product.trialDuration),
      trialPrice: $fc(product.trialPrice)
    }) as String
  }

  /** setting firstIndex as 1 if the product is trial (has label 0 already populated) */
  let index = !!labels[0] ? 1 : 0;

  if (variantSku && product.variants) {
    const v = product.variants.find(el => el.product.sku == variantSku).product;

    if (v.startup_cost && v.startup_cost > 0) {
      const fullPrice = v.full_price ? v.full_price : v.price_range.maximum_price.final_price.value
      if (isCurrentPlanTrial) {
        labels[1] = i18n.t("cart {startupCost}", { startupCost: $fc(v.startup_cost) })
        labels[2] = i18n.tc("cart {price} / each {durationQty} {duration}", 1, {
          price: $fc(v.discounted_price && v.discounted_price < fullPrice ? v.discounted_price : fullPrice),
          durationQty: 1,
          duration: i18n.tc(DURATION_LABEL_KEYS[span], 1)
        }) as String
      } else {
        labels[0] = i18n.tc("{durationQty} {duration} at {price}", 1, {
          price: $fc(v.promotional_price ?? (v.discounted_price ?? fullPrice)),
          durationQty: 1,
          duration: i18n.tc(DURATION_LABEL_KEYS[span], 1)
        }) as String
        labels[1] = i18n.t("cart {startupCost}", { startupCost: $fc(v.startup_cost) })
        labels[2] = i18n.tc("cart {price} / each {durationQty} {duration}", 1, {
          price: $fc(v.discounted_price && v.discounted_price < fullPrice ? v.discounted_price : fullPrice),
          durationQty: 1,
          duration: i18n.tc(DURATION_LABEL_KEYS[span], 1)
        }) as String
      }
    } else {


      if (v.price_range.maximum_price.final_price.value < v.price_range.maximum_price.regular_price.value) {
        labels[index] = getDiscountedLabel(v, i18n, $fc)

      } else { labels = getPriceByConfig(labels, index, i18n, $fc, product, span, v) }
    }
  } else {
    if (product.startupCost && product.startupCost > 0) {
      if (isCurrentPlanTrial) {
        labels[1] = i18n.t("cart {startupCost}", { startupCost: $fc(product.startupCost) })
        labels[2] = i18n.tc("cart {price} / each {durationQty} {duration}", 1, {
          price: $fc(product.discountedPrices[span] && product.discountedPrices[span] < product.prices[span] ? product.discountedPrices[span] : product.prices[span]),
          durationQty: 1,
          duration: i18n.tc(DURATION_LABEL_KEYS[span], 1)
        }) as String
      } else {
        labels[0] = i18n.tc("{durationQty} {duration} at {price}", 1, {
          price: $fc(product.promotional[span].price ?? (product.discountedPrices[span] ?? product.prices[span])),
          durationQty: 1,
          duration: i18n.tc(DURATION_LABEL_KEYS[span], 1)
        }) as String
        labels[1] = i18n.t("cart {startupCost}", { startupCost: $fc(product.startupCost) })
        labels[2] = i18n.tc("cart {price} / each {durationQty} {duration}", 1, {
          price: $fc(product.discountedPrices[span] && product.discountedPrices[span] < product.prices[span] ? product.discountedPrices[span] : product.prices[span]),
          durationQty: 1,
          duration: i18n.tc(DURATION_LABEL_KEYS[span], 1)
        }) as String

      }
    } else { labels = getPriceByConfig(labels, index, i18n, $fc, product, span, null) }
  }



  return labels
}

export const getCartItemPaymentFrequency = (cartItem, product) => {
  let paymentFrequency;
  if (product.__typename == BUNDLE_TYPENAME) {
    cartItem.bundle_options.find(el => el.values.find(bo => bo.configurable_options && bo.configurable_options.find(co => {
      paymentFrequency = co.value;
      return co.code == 'tipo_abbonamento'
    })))

  } else if (product.__typename == CONFIGURABLE_TYPENAME) {
    try {
      const tipoAbbonamentoOptions = product.configurable_options.find(el => el.attribute_code == 'tipo_abbonamento')
      if (tipoAbbonamentoOptions) {
        const selectedOptionUid = cartItem.configurable_options.find(el => el.configurable_product_option_uid == tipoAbbonamentoOptions.uid).configurable_product_option_value_uid;
        return tipoAbbonamentoOptions.values.find(el => el.uid == selectedOptionUid).value_index
      } else {
        if (product.variants[0].product.tipo_rinnovo == TIPO_RINNOVO_AUTOMATICO) return product.variants[0].product.tipo_abbonamento;
      }
    } catch (e) {
      console.warn('CartItemUid: ' + cartItem.uid + ', sku: ' + product.sku + 'Configurable without tipo_abbonamento')
    }
  } else if (product.tipo_rinnovo == TIPO_RINNOVO_AUTOMATICO && product.tipo_abbonamento) {
    return product.tipo_abbonamento
  }

  return paymentFrequency
}
