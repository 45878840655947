import { trackLink, trackView } from "~/helpers/trackings";
import { formatValueTrackings } from "./helpers";
import { STORE_CODE } from "./constants";

export const evidenceView = async (evidence) => {
    trackView({
        page_name: `${STORE_CODE}:testimonianza:${formatValueTrackings(evidence.slideName)}`,
        page_category_1: STORE_CODE,
        page_category_2: 'testimonianza',
        page_category_3: formatValueTrackings(evidence.slideName),
        page_category_10: 'testimonianza',
        page_content_status: 'free' 
    });
}

export const newsletterTrack = async () => {
    trackLink({
        event_name: `${STORE_CODE}:home:iscrizione-newsletter`,
        event_type: `iscriviti`
    }, null, `NWS @CLICK: iscriviti`)
}

export const footerLinkTrack = async (title, page, callback) => {
    trackLink({
        event_name: `${STORE_CODE}:home:navigazione-footer`,
        event_type: `${formatValueTrackings(title)}:${formatValueTrackings(page)}`
    }, callback, `FLT @CLICK: ${STORE_CODE}:home:navigazione-footer:${formatValueTrackings(title)}:${formatValueTrackings(page)}`)
}

export const logoTrack = async (callback?:Function) => {
    trackLink({
        event_name: `${STORE_CODE}:top-bar-menu:home`,
        event_type: `home`
    }, callback, `LGT @CLICK: home`)
}

export const topBarSezioniTrack = async (val) => {
    trackLink({
        event_name: `${STORE_CODE}:top-bar-menu:sezione`,
        event_type: `${formatValueTrackings(val)}`
    }, null, `TBST @CLICK: ${formatValueTrackings(val)}`)
}

export const topBarMenuTrack = async () => {
    trackLink({
        event_name: `${STORE_CODE}:top-bar-menu:menu`,
        event_type: `menu`
    }, null, `TBMT @CLICK: menu`)
}

export const menuSidebarLinkTrack = async (categoryName, subcategoryName, callback) => {
    trackLink({
        event_name: `${STORE_CODE}:top-bar-menu:voce-secondaria`,
        event_type: `${formatValueTrackings(categoryName)}:${formatValueTrackings(subcategoryName)}`
    }, callback, `MSLT @CLICK: ${formatValueTrackings(categoryName)}:${formatValueTrackings(subcategoryName)}`)
}

export const trackPageBuilderButton = async (eventName, href, target, callback?:Function) => {
    
    const callbackLink = callback ? callback :  () => {
        if (target == '_blank') {
            window.open(href, target).focus();
        } else {
            window.location.href = `${href}`
        }  
    };

    const parseEvent = eventName.match(/^(.*?)\[(.*?)\]$/);
    try {
        trackLink({
            event_name: `${parseEvent[1]}`,
            event_type: `${parseEvent[2]}`
        }, callbackLink, `TPBB @CLICK: ${parseEvent[1]}:${parseEvent[2]}`)
    } catch (e) {
        console.warn('Click event not done: Error: ',e)
        callbackLink();
    }
}