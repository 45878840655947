const getAccordionSectionProps = content => {
  const title = content.querySelector('[data-element=title]').innerHTML;
  return {
    id: title.replaceAll(' ','-'),
    title,
    content:content.querySelector('[data-element=content]')
  }
}
const getVideoProps = (content) => {
  /* const videoSrc = content.querySelector('.pagebuilder-video-container [data-element=video]').src
    const autoplay = !!content.querySelector('[autoplay]')
    const childPbStyle = content.querySelector('.pagebuilder-video-inner').dataset.pbStyle */
  const videoContent = content.innerHTML;
  return {
    /* videoSrc,
        autoplay,
        childPbStyle */
    videoContent,
    muted: true,
    loop:true
  };
};

const getBannerProps = (content) => {
  const link = content.querySelector('[data-element=link]');
  const wrapper = content.querySelector('[data-element=wrapper]');
  const overlay = content.querySelector('[data-element=overlay]');
  const videoOverlay = content.querySelector('[data-element=video_overlay]');
  const textContent = content.querySelector('[data-element=content]');
  const previewContent = content.querySelector('[data-element=preview_content]');
  const button = content.querySelector('[data-element=button]');
  return {
    buttonText: button?.innerHTML,
    title: content?.title,
    content: {
      text: textContent.innerHTML,
      previewText: previewContent?.innerHTML,
      pbStyle: textContent.dataset.pbStyle,
    },
    bannerDatas: {
      link: link ? link.href : null,
      linkTargetBlank: link && link.target == '_blank',
      ...wrapper.dataset,
      title: overlay.title,
      alt: overlay.ariaLabel,
      contentOverlay: {
        showType: content.dataset.showOverlay, /** @TODO check if needed */
        ...overlay.dataset,

      },
      ...(videoOverlay ? { videoOverlay: { ...videoOverlay.dataset } } : {}),

    },
  };
};

const getRowProps = (node) => {
  try {
    const innerDataset = { ...node.children[0].dataset }; /** Do not remove object assignation. it will break the children composition */
    innerDataset.childPbStyle = innerDataset.pbStyle;
    delete innerDataset.pbStyle;
    delete innerDataset.contentType;
    delete innerDataset.styles;
    delete innerDataset.mediaQueries;
    const videoOverlay = node.querySelector('[data-element=video_overlay]');
    return {
      ...innerDataset,
      videoOverlayColor: videoOverlay?.dataset?.videoOverlayColor,
    };
  } catch { return {}; }
};

const getProductsProps = (content) => {
  const products = content.querySelectorAll('.product-item-actions form[data-role=tocart-form]');
  return { skus: [...products].map((product) => product.dataset.productSku) };
};

export const getCustomProps = (node, content) => {
  switch (content.dataset.contentType) {
    case 'accordion-section': return getAccordionSectionProps(node);
    case 'row': return getRowProps(node);
    case 'video': return getVideoProps(content);
    case 'banner':
    case 'evidences':
    case 'evidence':
    case 'slide': return getBannerProps(content);
    case 'products': return getProductsProps(content);
    default: return {};
  }
};
