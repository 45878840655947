import { NO_CONSEGNA, TRACKINGS_ATTRIBUTE_SETS_CATEGORYWARE } from "../constants/product";
import { PALETTE } from "../constants/solepro/palette";
import { DarkenColor, rgbaToHex, getAxiosConfigs } from "./functions";
import axios from 'axios';

/**
 * @TODO use storeConfig
 */
export const getProductTypesParentCategory = (categories) => {
  return categories.find(el => el.name.toLowerCase() == 'prodotti')
}
/**
 * @TODO use storeConfig
 */
export const getTopicsParentCategory = (categories) => {
  return categories.find(el => el.name.toLowerCase() == 'tematiche')
}

export const getProductTypesCategories = (categories) => {
  let types = [];
  try {
    types = getProductTypesParentCategory(categories).children /**@TODO find prodotti by conf */
  } catch (e) {
    console.warn('getProductTypesCategories: Categoria di prodotti non trovata')
  }
  return types;
}

export const getTopicsCategories = categories => {
  let topics = [];
  try {
    topics = getTopicsParentCategory(categories).children /**@TODO find tematiche by conf */
  } catch (e) {
    console.warn('getTags: Categoria di tematiche non trovata')
  }
  return topics;
}

export const getTags = (categories, product) => {
  const types = getProductTypesCategories(categories)
  const topics = getTopicsCategories(categories)
  return (product?.categories || []).filter(el => !!types.find(type => type.uid == el.uid) || !!topics.find(topic => topic.uid == el.uid))
}
export const getTopicTags = (categories, product) => {
  const topics = getTopicsCategories(categories);
  return (product?.categories || []).filter(el => !!topics.find(topic => topic.uid == el.uid))
}

export const getProductTypeTag = (categories, product) => {
  const types = getProductTypesCategories(categories)
  return (product?.categories || []).find(el => !!types.find(type => type.uid == el.uid))//just one
}

export const getTagColor = (rgbaColor, defaultColor = '#000000') => {
  if (!rgbaColor) return defaultColor;
  const hexColor = rgbaToHex(rgbaColor).color.toUpperCase();
  if (PALETTE["1"].includes(hexColor)) {
    const index = PALETTE["1"].indexOf(hexColor);
    return PALETTE["4"][index]
  } else return DarkenColor(hexColor, 0.6)
}

export const getProductTypeColor = (categories, product, defaultColor = 'transparent') => {
  const types = getProductTypesCategories(categories);
  const category = product.categories.find(el => !!types.find(type => type.uid == el.uid)) //get just one if there are more
  return category ? getTagColor(category.tags_color, defaultColor) : defaultColor;
}
export const getProductTopicColor = (categories, product, defaultColor = 'transparent') => {
  const topics = getTopicsCategories(categories);
  const category = product.categories.find(el => !!topics.find(topic => topic.uid == el.uid)) //get just one if there are more
  return category ? getTagColor(category.tags_color, defaultColor) : defaultColor;
}

export const getProductTypeBgColor = (categories, product, defaultColor = 'transparent') => {
  const types = getProductTypesCategories(categories);
  const category = product.categories.find(el => !!types.find(type => type.uid == el.uid)) //get just one if there are more
  return category && category.tags_color ? category.tags_color : defaultColor;
}
export const getProductTopicBgColor = (categories, product, defaultColor = 'transparent') => {
  const topics = getTopicsCategories(categories);
  const category = product.categories.find(el => !!topics.find(topic => topic.uid == el.uid)) //get just one if there are more
  return category && category.tags_color ? category.tags_color : defaultColor;
}

export const extendProductsForBox = (categories, products) => {
  return products.map(p => {
    const productTypeTag = getProductTypeTag(categories, p);
    const topics = getTopicTags(categories, p);
    return {
      ...p,
      productTypeTag,
      topics,
      box_description: p.autore ? p.autore : (p.box_description ?? p?.short_description?.html),
      boxImage: p.thumbnail && p.thumbnail.url ?p.thumbnail : p.image
    }
  });
}

/**
 * Function to take first isbnCode inside an array of children.
 * This result will be used inside the Edigita endpoint to retrive the first cover available.
 * It also filters for physical elements to avoid incorrect ISBN.
 * @todo: Put edigitaCoverURL in configuration inside nuxt.
 * @todo: Variant type, i've several problem during build to add constants from products file.
 * 
 * @param product 
 * @returns String
 */
export const getCoverURL = (product) => {
  if (product.variants && product.variants.length > 0) {
    const firstIsbnCodeAvailable = product?.variants.filter((variant) => variant.product.type_id != 'simple') // DUNNO WHY, BUT I CAN'T IMPORT VARIABLES FROM CONSTANTS. WTF.
        .find((item) => item.product.codice_isbn != null);
    return firstIsbnCodeAvailable ? `https://edigita.cantook.net/p/${firstIsbnCodeAvailable.product.codice_isbn}` : false
  } else { return false }
}

export const getTopicHighlightsFilters = async (categories, app, config) => {

  const topicsCategory = getTopicsParentCategory(categories);
  const productsCategory = getProductTypesParentCategory(categories);

  /**
   * @function.
   * This function will starting taking in account the Tematiche and Prodotti categories of SolePro.
   * After, this will create an Object with the query to use inside the related service for fetch all the related products.
   * Furthermore, it will merge every Topic category with their relative count.
   * The usage of promise.all() is for speed up and parallelize fetch and front-end rendering.
   * Recap: first step query creation, second step counting fetch, third step harvest and merge.
   */

  const topicsFilters = topicsCategory.children.map((category) => {
    const queryString = `keyCategory=${topicsCategory.id}&targetCategories=${category.id}`; // Here we can handle a concatenation separated with comma.
    return {
      queryString,
      category
    }
  });

  const categoryCounters = await Promise.all(
    topicsFilters.map(async (filter) => {
      const { data: { result } } = await axios.get(`/sole-api/category/pro/categoryHighlightedTopics?${filter.queryString}`, { ...getAxiosConfigs(app, config)});
      return {
        productCounters: result[productsCategory.id],
        topic: filter.category
      }
    })
  )

  const topicResult = categoryCounters.map(({ productCounters, topic }) => {
    const enrichedCategory = Object.keys(productCounters).map(id => {
      const findCategory = productsCategory.children.find(children => children.id == id);
      const categorySpread = { ...findCategory };
      categorySpread.productMatched = productCounters[id];
      return categorySpread;
    })

    const enrichedTopic = { ...topic };
    enrichedTopic.relatedCategories = enrichedCategory.filter(el=>el.productMatched>0);
    return enrichedTopic;
  })

  return topicResult
}

export const getIsBook = (product) => {
  const categoryWareValue = TRACKINGS_ATTRIBUTE_SETS_CATEGORYWARE[product?.attributeSetName.toLowerCase()];
  return (categoryWareValue == TRACKINGS_ATTRIBUTE_SETS_CATEGORYWARE['libri e collane'])
}

export const isPaperOption = (v) => {
  const variant = v.value ?? v;
  try {
    return !NO_CONSEGNA.includes(variant.tipo_consegna.value??variant.tipo_consegna)
  } catch (e) {
    return false
  }
}