








































































import { defineComponent, computed, useContext, ref, watch } from '@nuxtjs/composition-api';
import { SfNotification, SfIcon } from '@storefront-ui/vue';
import { useCartView, useConfig, useUiNotification } from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { miniCartLink } from '~/helpers/trackings/24orepro/cart';
import MiniCartItems from './MiniCart/MiniCartItems.vue';

export default defineComponent({
  name: 'CartNotification',
  components: {
    SfNotification,
    SfIcon,
    MiniCartItems
  },
  data() {
        return {
            sprite: require('~/assets/solepro/spritemap.svg')
        }
    },
  setup() {
    const { notifications: allNotifications, dismissAll } = useUiNotification();
    const {config: srtoreConfig} = useConfig()
    const { cart } = useCart();
    const { totals } = useCartView();
    const { app } = useContext();
    const modal = ref();
    const notifications = computed(() => allNotifications.value.filter(el => !!el.isCartNotification))
    const showNotification = computed(()=>notifications.value.length>0)

    watch(showNotification,()=>{
      if(showNotification.value){
        document.body.style.overflowY='hidden'
        document.querySelector("#modal-backdrop").classList.remove('hidden');
      }else{
        document.body.style.overflowY='auto'
        document.querySelector("#modal-backdrop").classList.add('hidden');
      }
    })
    const messageDismissed = ref(false);
    const dismissMessage = ()=>{
      messageDismissed.value = true
    }
    return {
      storeCode: srtoreConfig.value?.store_code,
      notifications,
      cart,
      totals,
      paywallDomain: app.$config.paywallDomain,
      showNotification,
      modal,
      qty : computed(()=>cart.value?.total_quantity),
      cartId: computed(()=>cart.value?.id),
      dismissMessage,
      messageDismissed,
      dismissAll
    };
  },
  mounted() {
    document.querySelector("#modal-backdrop").addEventListener("click", this.closeModal)
    document.addEventListener("keydown", this.handleEscape);
    document.querySelector("#minicart-modal");
      const focusableElements = (this.$refs.modal as HTMLElement).querySelectorAll("a[href], button:not([disabled]), input, select");
      Array.from(focusableElements).forEach((el) => el.setAttribute('tabIndex', '0'));
  },
  beforeDestroy(){
    document.querySelector("#modal-backdrop").removeEventListener("click", this.closeModal)
    document.removeEventListener("keydown", this.handleEscape);
  },
  watch:{
    '$route.params.search':{
      handler: function(){
        this.dismissAll()
      },
      immediate: true,
      deep:true
    }
  },
  methods:{
    closeModal(){
      this.notifications.forEach(notification=>notification.dismiss())
    },
    trackMiniCart() {
      miniCartLink();
      this.closeModal();
    },
    handleEscape(evt){
      if (evt.key === "Escape") {
        this.closeModal()
      }
    }
  }
});
