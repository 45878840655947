



















import { defineComponent } from '@nuxtjs/composition-api'
export default defineComponent({
    props: {
        qty: {
            type: Number,
            required: true
        },
        minusFn: {
            type: Function,
            required: true
        },
        plusFn: {
            type: Function,
            required: true
        }
    },
    data() {
        return { 
            sprite: require('~/assets/solepro/spritemap.svg')
         }
    },
    setup() {

    },
})
