



















































































































import { defineComponent, useRouter } from '@nuxtjs/composition-api'
import { useConfig, useAutocomplete } from '~/composables';
import SearchModal from './SearchModal.vue';
import Teleport from 'vue2-teleport';
import ClientOnly from 'vue-client-only';
import { openSearchTrack, suggestionLinkTrack, sendSearchTrack } from '~/helpers/trackings/24orepro/search';

export default defineComponent({
    name: "SearchBar",
    components: {
        SearchModal,
        ClientOnly,
        Teleport
    },
    data() {
        return {
            buttonRef: null,
            queryTerm: '',
            isSearchOpen: false,
        }
    },
    mounted() {
        if (process.client) {
            const elements = document.querySelectorAll(`[id^="searchSuggestionsButton"]`);
            this.buttonRef = elements[0];
        }
    },
    setup(props) {
        const { loadSuggestedTermsResults, resetSuggestedTerms, suggestedTerms } = useAutocomplete();
        const { config } = useConfig();
        const router = useRouter();
        const updateRoute = (term) => router.push(`${term}`)

        return {
            storeCode: config.value.store_code,
            loadSuggestedTermsResults,
            resetSuggestedTerms,
            suggestedTerms,
            updateRoute
        }
    },
    methods: {
        async getAutocompleteValues () {
            return this.queryTerm.length > 3 ? this.loadSuggestedTermsResults(this.queryTerm) : this.resetSuggestedTerms();
        },
        openSearch() {
            this.isSearchOpen = true;
            openSearchTrack()
        },
        sendSearch(callback = null) {
            this.updateRoute(`/${this.storeCode}/search/${this.queryTerm}`)
            sendSearchTrack(this.queryTerm);
            this.closeSearch(callback);
        },
        handleSuggested(val) {
            this.queryTerm = val;
            this.sendSearch()
            suggestionLinkTrack(val);
        },
        closeSearch(cleanupCallback = null) {
            this.isSearchOpen = false;
            this.queryTerm = '';
            this.resetSuggestedTerms();
            
            // Clean mobile modal with accessibility functions.
            if (cleanupCallback) {
                cleanupCallback();
            }
        }
    }
})

