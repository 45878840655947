








import { defineComponent } from '@vue/composition-api';
import { isString } from 'lodash-es';
import { PropType } from 'vue';
import { PageBuilderType } from '~/types/solepro/PageBuilder';
import { getComponent } from '~/utils/solepro/CmsComponentImport';
import { GenericObjectType } from '~/utils/types';

export default defineComponent({
  name: 'PageBuilderComponent',
  props: {
    node: {
      type: Object as PropType<PageBuilderType>,
      required: true,
    },
    customProps: {
      type: Object as PropType<GenericObjectType>,
      default: () => { return {} }
    }
  },
  setup(props) {

    const componentName = props.node.contentType;
    const component = getComponent(componentName, props.node.cssClasses);
    const componentExists = component !== 'div';

    return {
      componentName,
      component,
      componentExists,
    };
  },
  computed: {
    renderHTML() {
      if (process.server) {
        //@ts-ignore
        return isString(this.node.DOMContent) ? this.node.DOMContent : this.node.DOMContent.outerHTML;  
      }

      //@ts-ignore
      return this.node.DOMContent;
    },
    hasChildren(): Boolean {
      const { children } = this.node;
      return children && children.length > 0;
    },
  },
  mounted() {
    try{
      const targets = document.querySelectorAll('[data-target]')
      targets.forEach(el=>{
        el.setAttribute('target','_blank')
      })

    }catch(e){
      console.warn('NOT QSA')
    }
  }

});
