
export const PALETTE = {
  "1": [
    "#F7F7F7",
    "#FFF0F0",
    "#F0F9FF",
    "#EEFCF3",
    "#FFF0E5",
    "#EBFCFF",
    "#F9E6FF",
    "#FFF6E6",
    "#FFE3F4"],
  "2": [
    "#D1D1D1",
    "#F6B1B1",
    "#99D3FF",
    "#99E6B4",
    "#FDB681",
    "#A2EAF6",
    "#F0C3FE",
    "#FFE6B7",
    "#FDBDE5",
  ],
  "3": [
    '#949494',
    '#E2283B',
    '#158BFF',
    '#19AE4A',
    '#F56600',
    '#3FABC0',
    '#D863FC',
    '#F0AE33',
    '#E150AA',
  ],
  "4": [
    '#595959',
    '#AD0028',
    '#0247A1',
    '#0B6029',
    '#8A3700',
    '#055C6B',
    '#9A00C9',
    '#98670B',
    '#C4037A',
  ],
  "5": [
    '#3D3D3D',
    '#520018',
    '#002652',
    '#052E14',
    '#421B00',
    '#05282E',
    '#62007F',
    '#5C3C00',
    '#8E0158',
  ],
}