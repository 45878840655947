


















import ClientOnly from 'vue-client-only'
import { useRoute, defineComponent, ref } from '@nuxtjs/composition-api';
import PageLoading from '~/components/GlobalComponents/PageLoading.vue';
import Footer from '~/components/solepro/Footer.vue';
import Header from '~/components/solepro/Header.vue';
import { usePageStore } from '~/stores/page';
import { SOLEPRO_LINKS, SOLEPRO_SCRIPTS, SOLEPRO_TITLE, SOLEPRO_META } from '~/utils/constants/nuxt/metaInfos';
export default defineComponent({
  name: 'SoleproLayout',
  components: {
    PageLoading,
    Header,
    Footer,
    ClientOnly
  },
  head() {
    return {
      titleTemplate: SOLEPRO_TITLE,
      link: SOLEPRO_LINKS,
      script: SOLEPRO_SCRIPTS,
      meta: SOLEPRO_META
    };
  },
  setup() {
    const route = useRoute();
    const { routeData } = usePageStore();
    const hasBorder = ref(false);
    
    hasBorder.value = routeData?.type == 'CMS_PAGE';
    return {
      route,
      hasBorder
    };
  },
});
