










import { defineComponent, ref, useContext, useFetch } from '@nuxtjs/composition-api'
import axios from 'axios';
import { PropType } from 'vue';
import { useApi, useCart, useCartView, useConfig } from '~/composables';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { extendProductsForBox, getIsBook } from '~/utils/solepro/products';
import { ProductForProductPageType } from '~/utils/types/solepro/Product';
import GET_CART_ITEM_DATAS from '~/modules/catalog/product/queries/solepro/getCartItemDatas.gql'
import Item from './Item.vue';
import { getCartItemPaymentFrequency, getSecondLine } from '~/utils/helpers/cart';
import { getAxiosConfigs } from '~/utils/solepro/functions';

export default defineComponent({
    props: {
        item: {
            type: Object as PropType<ProductForProductPageType>,
            required: false
        },
        dismissMessage: {
            type: Function,
            default: () => { }
        }
    },
    components: {
        Item
    },
    data() {
        return {
            sprite: require('~/assets/solepro/spritemap.svg')
        }
    },
    setup(props) {
        const { item } = props;
        const { config } = useConfig()
        const { app } = useContext();
        const { products: cartItems } = useCartView();
        const products = ref([])
        const { updateItemQty, removeItem, loading, load: loadCart } = useCart();
        const categoryStore = useCategoryStore();
        const { query } = useApi();
        const { app: { i18n } } = useContext();
        const categories = ref([])
        const getMiniCartDatas = async () => {
            await loadCart()
            const skus = cartItems.value.map(el => {
                let tmp = [el.product.sku]
                el['configured_variant'] ? tmp.push(el['configured_variant'].sku) : null;
                return tmp;
            }).flat()

            const { data } = await query(GET_CART_ITEM_DATAS, { filter: { sku: { in: skus } } });
            const { data: attributeSets } = await axios.get('/sole-api/product/getAttributeSets',{...getAxiosConfigs(app, config)});

            return cartItems.value.map((item, index) => {
                const extendedDatas = extendProductsForBox(categories.value, [item.product])[0];
                const variantSku = item['configured_variant'] ? item['configured_variant'].sku : null;
                const variant = data['products'].items.find(el => el.sku == variantSku);
                const variantDatas = variantSku && variant ? extendProductsForBox(categories.value, [variant])[0] : {};
                let parentProduct = data['products'].items.find(el => el.sku == item.product.sku)
                parentProduct.attributeSetName = attributeSets[parentProduct.attribute_set_id];
                const paymentFrequency = getCartItemPaymentFrequency(item, parentProduct)
                return {
                    ...item,
                    isBook: getIsBook(parentProduct),
                    product: {
                        ...item.product,
                        ...(parentProduct),
                        ...extendedDatas,
                        ...(variantSku && variantDatas.boxImage ? { boxImage: variantDatas.boxImage } : {}),
                    },
                    secondLine: getSecondLine(i18n, item, parentProduct, variantSku, paymentFrequency)
                }
            })
        }
        useFetch(async () => {
            await categoryStore.load()
            categories.value = categoryStore.rawCategories?.items[0].children;
            products.value = await getMiniCartDatas()
        })

        return {
            products,
            updateItemQty,
            getMiniCartDatas,
            removeItem,
            loading
        }
    },
    methods: {
        async updateQty(item, qty) {
            /** posso aggiungere se ho meno di 10 a carrello di quel prodotto
             * posso rimuovere se ho almeno 2 a carrello di quel prodotto
             */
            this.dismissMessage();
            if ((qty > 0 && item.quantity < 10) || (qty < 0 && item.quantity > 1)) {
                await this.updateItemQty({ product: item, quantity: item.quantity + qty });

            } else if (qty < 0 && item.quantity == 1) {
                await this.removeItem({ product: item });

            }
            this.products = await this.getMiniCartDatas();
        }
    }
})
