const SELECTORS = {
    siteLogo: '#logo-link',
    tabPanel: {
        tabpanel: '[role="tabpanel"]',
        tablist: '[role="tablist"]',
        tabs: '.js-accessible-tab',
        tabwrp: '.js-accessible-tabs-wrp'
    },
    modal: {
        trigger: '.js-open-accessible-dialog',
        target: '.js-accessible-dialog',
        closeButton: '.js-close-accessible-dialog',
        refreshButton: '.js-refresh-accessible-dialog'
    },
    focusableElements: 'a[href]:not([tabindex="-1"]), button:not([disabled]):not([tabindex="-1"]), input:not([disabled]):not([tabindex="-1"]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
};

/**
 * This function handles opening of an accessible modal.
 * @param {HTMLElement} modal - the modal element
 * @param {HTMLElement} firstFocusable - the first focusable element inside the modal
 */
function openModal(modal, firstFocusable, focusableElements) {
    if (focusableElements.length === 0) {
        focusableElements = modal.find(SELECTORS.focusableElements);
    }
    // make interactive elements tabbable
    [...focusableElements].forEach((el) => el.setAttribute('tabIndex', '0'));
    // need to wait some time since some modals are hidden with display: none
    setTimeout(function () {
        modal.setAttribute('aria-hidden', 'false');
        modal.setAttribute('aria-modal', 'true');
        firstFocusable.focus();
    }, 200);
}

/**
 * This function handles closing of an accessible modal.
 * @param {HTMLElement} modal - the modal element
 * @param {HTMLElement} openBtn - the button that triggers the opening of the modal
 */
function closeModal(modal, openBtn, focusableElements) {
    // make interactive elements non-tabbable
    [...focusableElements].forEach((el) => el.setAttribute('tabIndex', '-1'));
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('aria-modal', 'false');

    if (modal.getAttribute('id') === "minicart-modal") {
        document.querySelector('.js-cart-btn').focus();
    } else {
        openBtn.focus();
    }
}

/**
 * This function handles the functions to trigger after a modal is closed or it's opened. It's used to handle the focus on the modal elements.
 * It adds event listeners on the elements that trigger the open/close functionality of the modal.
 * It only handles the accessible features, so to toggle the visibility of the modal a different logic should be implemented.
 * This logic follows the ARIA Dialog pattern @see https://www.w3.org/WAI/ARIA/apg/patterns/dialog-modal/examples/dialog/
 * @param {HTMLButtonElement} openBtn - the button that triggers the opening of the modal
 */
function accessibleModal(openBtn) {
    var modal = document.querySelector(openBtn.getAttribute('data-target'));
    var closeBtn = modal.querySelector(SELECTORS.modal.closeButton);
    var refreshBtn = modal.querySelector(SELECTORS.modal.refreshButton);
    var focusableElements = modal.querySelectorAll(SELECTORS.focusableElements);
    var firstFocusable = focusableElements[0];
    var lastFocusable = focusableElements[focusableElements.length - 1];
    [...focusableElements].forEach((el) => el.setAttribute('tabIndex', '-1'));

    openBtn.addEventListener('click', function () {
        if (openBtn.getAttribute('aria-expanded') === 'true') {
            closeModal(modal, openBtn, focusableElements);
        } else {
            openModal(modal, firstFocusable, focusableElements);
        }
    });

    openBtn.addEventListener('keydown', function (evt) {
        if (evt.key === 'Enter' || evt.key === ' ') {
            if (openBtn.getAttribute('aria-expanded') === 'true') {
                closeModal(modal, openBtn, focusableElements);
            } else {
                openModal(modal, firstFocusable, focusableElements);
            }
        }
    });

    closeBtn.addEventListener('click', function () {
        closeModal(modal, openBtn, focusableElements);
    });

    if (refreshBtn) {
        refreshBtn.addEventListener('click', function () {
            closeModal(modal, openBtn, focusableElements);
        });
    }

    modal.addEventListener('keydown', function (e) {
        if (e.key === 'Escape') {
            closeModal(modal, openBtn, focusableElements);
        }

        if (e.key === 'Tab') {
            if (e.shiftKey) {
                if (document.activeElement === firstFocusable) {
                    e.preventDefault();
                    lastFocusable.focus();
                }
            } else {
                if (document.activeElement === lastFocusable) {
                    e.preventDefault();
                    firstFocusable.focus();
                }
            }
        }
    });
}

/**
 * This function handles the focus trap inside a container element.
 * It finds all the focusable elements inside the container
 * and handles the focus on the first and last focusable elements.
 * @param {HTMLElement} container - the container element to handle the focus trap
 * @param {HTMLElement} firstFocusableParam - the first focusable element inside the container
 * @param {HTMLElement} lastFocusableParam - the last focusable element inside the container
 */
function simpleFocusTrap(container, firstFocusableParam = undefined) {
    var focusableElements = container.querySelectorAll(SELECTORS.focusableElements);
    var firstFocusable = focusableElements[0];
    var lastFocusable = focusableElements[focusableElements.length - 1];

    container.addEventListener('keydown', function (e) {
        if (e.key === 'Tab') {
            if (e.shiftKey) {
                if (document.activeElement === firstFocusable) {
                    e.preventDefault();
                    if (firstFocusableParam) {
                        firstFocusableParam.focus();
                    } else {
                        lastFocusable.focus();
                    }
                }
            } else {
                if (document.activeElement === lastFocusable) {
                    e.preventDefault();
                    if (firstFocusableParam) {
                        firstFocusableParam.focus();
                    } else {
                        firstFocusable.focus();
                    }
                }
            }
        }
    });
}

/**
 * This function removes elements from the accessibility tree by setting the tabindex to -1.
 * @param {HTMLElement} container - the container element to remove the elements from
 */
function removeElementsFromAccessibilityTree(container) {
    const elements = container.querySelectorAll(SELECTORS.focusableElements);
    elements.forEach((el) => {
        el.setAttribute('tabindex', '-1');
    });
}

/**
 * This function handles the focus on the first focusable element inside a section.
 * It's used to handle the focus on the first focusable element when a section is opened.
 * @param {HTMLElement} section - the section to focus on
 * @param {Boolean} focusOnFirst - if true, it focuses on the first focusable element inside the section, otherwise it focuses on the last focusable element
 */
function focusOnSection(section, focusOnFirst = true) {
    var focusableElements = section.querySelectorAll(SELECTORS.focusableElements);

    if (focusableElements.length) {
        if (!focusOnFirst) {
            var lastFocusableElement = focusableElements[focusableElements.length - 1];
            lastFocusableElement.focus();
        } else {
            var firstFocusableElement = focusableElements[0];
            firstFocusableElement.focus();
        }
    }
}

export default {
    accessibleModal,
    focusOnSection,
    simpleFocusTrap,
    removeElementsFromAccessibilityTree
};