






















































































































































































































































import { computed, defineComponent, nextTick, onMounted, ref, useFetch, PropType, useRouter } from '@nuxtjs/composition-api'
import { useConfig, useContent } from '~/composables';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import accessibility from '~/utils/solepro/accessibility';
import HTMLContent from '~/components/solepro/HTMLContent.vue';
import { CmsBlock } from '@vue-storefront/magento-types';
import { CATEGORIES_TO_REDIRECT } from '~/utils/constants/solepro/categories';
import { menuSidebarLinkTrack } from '~/helpers/trackings/24orepro/components';

export default defineComponent({
  props: {
    firstLevelOpened: {
      type: Boolean,
      default: false
    },
    secondLevelOpened: {
      type: Number
    },
    closeMenu: {
      type: Function,
      required: true
    },
    openMenu: {
      type: Function,
      required: true
    },
    menuButton: {
      validator: (prop) => prop instanceof HTMLElement || typeof prop === "object",
      required: true
    }
  },
  components: {
    HTMLContent
  },
  setup(props, context) {
    const { config: storeConfig } = useConfig();
    const router = useRouter();
    const storeCode = storeConfig.value.store_code;
    const megaMenu = ref(null);
    const categories = useCategoryStore();
    const cmsBlocks = ref({});
    const newsLink = ref(null);
    const menuCategories = ref([]);
    const { loadBlocks } = useContent();
    const replaceDashes = (category) => {
      return category.url_path.replace('-', '_')
    }
    const prefix = 'menu_';
    const getBlock = category => {
      return cmsBlocks.value[prefix + replaceDashes(category)]?.content
    }
    const identifiers = ref([ // @TODO check se si possono prendere i blocchi con una logica like
      `${prefix}chi_siamo`, `${prefix}contattaci`,
      `${prefix}chi_siamo_1`, `${prefix}contattaci_1`,
      `${prefix}chi_siamo_2`, `${prefix}contattaci_2`,
      `${prefix}root_block`
    ])
    const getIdentifiers = category => {
      const categoryIdentifiers = identifiers.value.filter(el => el.startsWith(prefix + replaceDashes(category)))
      return categoryIdentifiers.filter(el => !!cmsBlocks[el])
    }

    const chiSiamoBlocks = ref([]);
    const contactUsBlocks = ref([]);
    const newsLinkCMSBlock = ref();
    useFetch(async () => {
      await categories.load();
      menuCategories.value = categories.rawCategories?.items[0].children.filter(el => !!el.include_in_menu);
      identifiers.value = identifiers.value.concat(menuCategories.value.map((cat, i) => {
        const base = `${prefix}${replaceDashes(cat)}`;
        return [base, `${base}_1`, `${base}_2`]
      }).flat())
      const blocks = await loadBlocks({ identifiers: identifiers.value });
      let tmp = {};
      blocks.forEach((el, index) => {
        !!el ? tmp[identifiers.value[index]] = el : null;
      });
      Object.keys(tmp).length > 0 ? cmsBlocks.value = tmp : null;
      const cmsBlocksValues = Object.values(cmsBlocks.value) as CmsBlock[];
      chiSiamoBlocks.value = cmsBlocksValues.filter(el => el.identifier.startsWith(prefix + 'chi_siamo'));
      contactUsBlocks.value = cmsBlocksValues.filter(el => el.identifier.startsWith(prefix + 'contattaci'));
      newsLinkCMSBlock.value = cmsBlocksValues.find(el => el.identifier == (prefix + 'root_block'));
      nextTick(() => {
        Object.keys(context.refs).filter(key => key.startsWith('menuLevelRef_')).forEach(refKey => {
          accessibility.simpleFocusTrap(context.refs[refKey][0]);
        })

      })
    })

    const initialTabIndex = ref(0);
    const isDesktop = ref(false);
    onMounted(() => {
      if (window && window.innerWidth > 1024) {
        initialTabIndex.value = -1;
        isDesktop.value = true;
      }
    })

    const trackMenuSidebarLink = (categoryName, subcategoryName, callback) => {
      menuSidebarLinkTrack(categoryName, subcategoryName, callback)
    };

    return {
      megaMenu,
      menuCategories,
      storeCode,
      replaceDashes,
      getIdentifiers,
      initialTabIndex,
      getBlock,
      chiSiamoBlocks,
      contactUsBlocks,
      newsLinkCMSBlock,
      isDesktop,
      newsLink,
      categoriesToRedirect: CATEGORIES_TO_REDIRECT,
      //@ts-ignore
      closeMenuProp: props.closeMenu,
      trackMenuSidebarLink,
      router
    }
  },
  watch: {
    firstLevelOpened: function (newVal) {
      if (newVal && !this.isDesktop) {
        this.newsLink.$el.querySelector('a')?.setAttribute('tab-index', '-1')
      }
      //@ts-ignore
      accessibility.simpleFocusTrap(this.megaMenu, this.menuButton);

    },
    '$route.params.search': {
    handler: function() {
      this.closeMenuProp()
      //this.closeMenuLevel(null);
    },
    deep: true,
    immediate: true
  }

  },
  methods: {
    toggleThisMenu(event, index){
      /** lo so fa schifo, ho fretta, scusate, lo devo sistemare */
      if(this.secondLevelOpened==index){
        this.closeMenuLevel(event, index)
        //this.toggleMenuLevel(event, index)
      }else{
        this.toggleMenuLevel(event, index)
      }
    },
    goToLink(link, target) {
      if(target=='_blank'){
        window.open(link, target).focus()
      }else{
        this.router.push({path: link})
      }
    },
    toggleMenuLevel(event, index) {
      //@ts-ignore
      this.$props.closeMenu()
      const thisMenuLevel = event.currentTarget;
      nextTick(() => {
        const links = thisMenuLevel.querySelectorAll("a, .js-menu-back");
        links.forEach((link) => {
          link.tabIndex = 0;
        });

        if (window.innerWidth > 1024) {
          links[1]?.focus();
        } else {
          links[0].focus();
        }
      }) //@TODO test with two cms blocks with links

      //this.megaMenu.classList.remove("overflow-auto");
      //@ts-ignore
      this.$props.openMenu(index)
    },
    closeMenuLevel(event, index) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      //this.closeMenuLevel(event,index);
      //@ts-ignore
      this.closeMenu()
      //@ts-ignore
      this.$props.openMenu(null) 
      const menuLevelLayer = event ? event.currentTarget.closest(".js-menu-item"):document.querySelector('.js-menu-item');
      menuLevelLayer.querySelector(".js-menu-item-btn").focus();
      const links = menuLevelLayer.querySelectorAll("a, .js-menu-back");
      links.forEach((link) => {
        link.tabIndex = -1;
      });
    },
    handleKeyDownEscape(event, index) {
      if (event.key === "Escape") {
        this.closeMenuLevel(event, index);
      }
    }
  }
})
