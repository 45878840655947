




































































import { defineComponent } from '@nuxtjs/composition-api'
import QuantitySelector from '../ProductPage/PurchaseOptions/QuantitySelector.vue';
import { cartProductQuantityLink } from '~/helpers/trackings/24orepro/cart';

export default defineComponent({
    props:{
        item:{
            required: true,
            type: Object //@TODO define type
        },
        updateQty:{
            type: Function,
            required: true
        },
        isFullCart:{
            type:Boolean,
            default: false
        },
        removeFn:{
            type: Function,
            default:()=>{console.log('default remove fn')}
        }
    },
    components:{
        QuantitySelector
    },
    data(){
        return{
            sprite: require('~/assets/solepro/spritemap.svg')
        }
    },
    methods: {
        trackQuantityEvent(label) {
            cartProductQuantityLink(this.item, label)
        }
    }
})
