

















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({

    props: {
        disableOnDesktop: {
            type: Boolean,
            default: true
        },
        onGroupIndex: { // when >0 this accordion is part of a group of accordions, so the border must be only bottom for index>0 (to avoid border overlaps)
            type: Number,
            default: 0
        },
        bottomBorder:{
            type: Boolean,
            default: true
        },
        trackFunction: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            name: 'Accordion',//DO NOT REMOVE
            isOpen: false, //will be managed on accordion group if the parent is an accordion group
        };
    },
    methods: {
        /**
         * When this accordion is part of an accordion group, the opening is managed by the AccordionGroup
         * otherwise it will toggle itself
         */
        toggleAccordion() {
        // @ts-ignore
            (this.$parent['name']=='AccordionGroup') ? this.$parent.$emit("toggle", this._uid) : this.isOpen=!this.isOpen;
            this.trackFunction ? this.trackFunction() : null;
        },
    },
    setup(props, context){
        const hasDesktopTrigger = !!context.slots.desktopTrigger;
        return {
            hasDesktopTrigger
        }
    }
})
