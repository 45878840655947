export const IN_STOCK = 'IN_STOCK';
export const OUT_OF_STOCK = 'OUT_OF_STOCK';
export const NOT_PHYSICAL = 'digitale';
export const PHYSICAL = 'cartaceo';
export const VIRTUAL_TYPE_ID = 'virtual';
export const SIMPLE_TYPE_ID = 'simple';
export const CONFIGURABLE_TYPE_ID = 'configurable';
export const BUNDLE_TYPE_ID = 'bundle';
export const CONFIGURABLE_TYPENAME = 'ConfigurableProduct';
export const BUNDLE_TYPENAME = 'BundleProduct';
export const NO_CONSEGNA = [0, 1, '0', '1'];
export const DURATION_SPAN_MONTHLY = 'M';
export const DURATION_SPAN_QUARTERLY = 'Q';
export const DURATION_SPAN_HALFYEARLY = 'S';
export const DURATION_SPAN_YEARLY = 'Y';
export const DURATION_SPAN_BIENNIAL = 'B';

export const DURATION_MATCHING = {
  1: 'M',
  3: 'Q',
  6: 'S',
  12: 'Y',
  24: 'B'
}

/** L'ideale sarebbe calcolarlo, mappato per ottimizzare i tempi di esecuzione in caso di molti prodotti contemporaneamente visualizzati */
export const DURATION_MATCHING_REVERSE = {
  'M': 1,
  'Q': 3,
  'S': 6,
  'Y': 12,
  'B': 24,
}

export const SUBSCRIPTION_DEFAULT_BILLING_PERIOD = {
  "Mensile": 'M',
  "Trimestrale": 'Q',
  "Semestrale": 'S',
  "Annuale": 'Y',
  "Biennale": 'B'
}

export const TIPO_RINNOVO_AUTOMATICO = 'RicorrenteMultiutenza';

export const DIGITAL24SKUS = ["QUOTDIG", "Z.00NP_MXXXX_AXXXY"]

export const ADD_TO_CART_TIPO_ABBONAMENTO_PARAMS = {
  B: 'biennale',
  Y: 'annuale',
  S: 'semestrale',
  Q: 'trimestrale',
  M: 'mensile'
}


export const TRACKINGS_ATTRIBUTE_SETS_CATEGORYWARE = {
  'libri e collane': 'libro',
  'periodici': 'periodico',
  'software':'software'
}

export const QUESITI_ATTRIBUTE_SET_NAME = 'Quesiti';
export const META_PDP_SET_NAME = 'Meta-PDP Set';

export const FEMININE_CATEGORIES = ['banche dati', 'soluzioni ai', 'certificazioni']