import { trackLink, trackView } from "~/helpers/trackings";
import { formatValueTrackings, getDiscountPrice, getCartItemsNames } from "./helpers";
import { STORE_CODE } from "./constants";

export const trackCartView = async (products) => {
    trackView({
        page_name: `${STORE_CODE}:carrello:${getCartItemsNames(products)}`,
        page_category_1: STORE_CODE,
        page_category_2: 'carrello',
        page_category_3: getCartItemsNames(products),
        page_category_10: 'carrello',
        page_content_status: 'free' 
    });
}

export const miniCartLink = async () => {
    trackLink({
        event_name: `${STORE_CODE}:top-bar-menu:visualizza-carrello`,
        event_type: `visualizza-carrello`
    }, null, `MCL @CLICK: visualizza-carrello`)
}

export const cartProductRemoveLink = async (cartItem) => {
    const { product: { name } } = cartItem || {};
    
    trackLink({
        event_name: `${STORE_CODE}:carrello:rimuovi-prodotto`,
        event_type: `rimuovi:${formatValueTrackings(name)}`
    }, null, `CPRL @CLICK: rimuovi:${formatValueTrackings(name)}`)
}

export const cartProductQuantityLink = async (cartItem, label) => {
    const { product: { name } } = cartItem || {};
    
    trackLink({
        event_name: `${STORE_CODE}:carrello:modifica-quantità`,
        event_type: `${label}:${formatValueTrackings(name)}`
    }, null, `CPQL @CLICK: ${label}:${formatValueTrackings(name)}`)
}

export const cartCouponApplyLink = async (couponCode, discounts, totals) => {
    trackLink({
        event_name: `${STORE_CODE}:carrello:applica-sconto`,
        event_type: `applica:${couponCode}`,
        coupon_code: `${couponCode}`,
        discount_value: `${getDiscountPrice(discounts, totals)}`
    }, null, `CCAL @CLICK: applica:${formatValueTrackings(couponCode)}:${getDiscountPrice(discounts, totals)}`)
}

export const cartGoToCheckoutLink = async (totals, shipping, callback) => {    
    trackLink({
        event_name: `${STORE_CODE}:carrello:vai-al-pagamento`,
        event_type: `applica:vai-al-pagamento`,
        shipment_price: `${shipping}`,
        transaction_value: `${totals.total}`
    }, callback, `CGTC @CLICK: applica:vai-al-pagamento:${shipping}:${totals.total}`)
}