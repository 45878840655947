import { trackLink, trackView } from "~/helpers/trackings";
import { formatValueTrackings } from "./helpers";
import { STORE_CODE } from "./constants";

export const searchView = async (term, total) => {
    trackView({
        page_name: `${STORE_CODE}:${formatValueTrackings(term)}:${total}`,
        page_category_1: STORE_CODE,
        page_category_2: 'risultati-ricerca',
        page_category_3: formatValueTrackings(term),
        page_category_4: total,
        page_category_10: 'risultati-ricerca',
        page_content_status: 'free' 
    });
}

export const openSearchTrack = async () => {
    trackLink({
        event_name: `${STORE_CODE}:ricerca:barra-di-ricerca`,
        event_type: `click-barra-di-ricerca`
    }, null, `OST @CLICK: click-barra-di-ricerca`)
}

export const suggestionLinkTrack = async (val) => {
    trackLink({
        event_name: `${STORE_CODE}:ricerca:barra-di-ricerca`,
        event_type: `suggerimento-barra-di-ricerca:${formatValueTrackings(val)}`
    }, null, `SLT @CLICK: suggerimento-barra-di-ricerca:${formatValueTrackings(val)}`)
}

export const sendSearchTrack = async (val) => {
    trackLink({
        event_name: `${STORE_CODE}:ricerca:barra-di-ricerca`,
        event_type: `${formatValueTrackings(val)}`
    }, null, `SST @CLICK: ${formatValueTrackings(val)}`)
}