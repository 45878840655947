





















































































































































import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api'
import { useConfig } from '~/composables';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import Accordion from '~/components/solepro/Accordion.vue';
import AccordionGroup from '~/components/solepro/AccordionGroup.vue';
import { FOOTER_LINKS } from '~/utils/constants/solepro/footer';
import PDPHelpBlock from '~/components/solepro/PDPHelpBlock.vue';
import { CATEGORIES_TO_REDIRECT } from '~/utils/constants/solepro/categories';
import { footerLinkTrack } from '~/helpers/trackings/24orepro/components';

export default defineComponent({
    components: {
        Accordion,
        AccordionGroup,
        PDPHelpBlock
    },
    setup() {
        const footerCategories = ref([]);
        const categories = useCategoryStore();
        const { config: storeConfig } = useConfig();
        
        const trackFooterLink = (e, title, page, callback) => {
            e.preventDefault(); 
            footerLinkTrack(title, page, callback) 
        };

        const trackFooterNuxtLink = (title, page) => {
            footerLinkTrack(title, page, null)
        }

        useFetch(async () => {
            await categories.load()
            footerCategories.value = categories.rawCategories?.items[0].children.filter(el => !!el.include_in_menu);

        });
        return {
            trackFooterLink,
            trackFooterNuxtLink,
            footerCategories,
            FOOTER_LINKS,
            copyrightText: storeConfig.value.copyright,
            PI: storeConfig.value.absolute_footer,
            storeCode: storeConfig.value.store_code,
            categoriesToRedirect: CATEGORIES_TO_REDIRECT
        }

    },
    methods: {
        goToLink(link, target) {
            if (target == '_blank') {
                window.open(link, target).focus();
            } else {
                window.location.href = link
            }
        }
    }
})
