export default `query getCartIemDatas(
  $filter: ProductAttributeFilterInput
) {
  products(filter: $filter) {
    items {
      sku
      type_id
      tipo_rinnovo
      tipo_abbonamento
      attribute_set_id
      ... on ConfigurableProduct{
      configurable_options{
            uid
            id attribute_id
            attribute_code
            label values { value_index label uid }
        }
        variants{
          product{
            sku
            name
            tipo_consegna
            tipo_abbonamento
            tipo_rinnovo
            natura
          }
        }
      }
        ... on BundleProduct{
        
          items{
            option_id
            sku
            options{
              product{
                tipo_abbonamento
                tipo_rinnovo
                sku
                name
              }
            }
          }
        
        }
      thumbnail {
            label
            disabled
            url
        }
      image {
            label
            url
        }
      categories{ uid id name tags_color }
    }
  }
}`