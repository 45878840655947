import { useContext } from '@nuxtjs/composition-api';
import { GenericObjectType } from '~/utils/types';
import { getCustomProps } from './CmsComponentProps';
import { getClasses, getPageBuilderStyleNode, getStyles } from './CmsComponentStylings';
import { ALLOWED_COMPONENTS } from './CmsComponentImport';
import { parseClasses } from './functions';
import { integrations } from '~/middleware.config';


const parseMediaQueries = (cssClasses: Array<string>) => {
  return cssClasses.map(el => parseClasses(el))
}

/**
 * Retrieve the CSS classes from a content type node
 * @param node
 * @returns {{cssClasses: any}}
 */
export function getCssClasses(node, dataset) {
  return {
    cssClasses: parseMediaQueries([...getClasses(node, dataset), ...node.getAttribute('class')
      ? node.getAttribute('class').split(' ')
      : []]),
  };
}

/**
 * Retrieve if CSS display property is set to none from a content type node
 *
 * @param node
 * @returns {{isHidden: boolean}}
 */
export function getIsHidden(node) {
  return {
    isHidden: node.style.display === 'none',
  };
}

/**
 * Converts a CSS string style into a JSX object inline style
 *
 * @param {String} style
 * @returns {Object}
 */
export function cssToJSXStyle(style) {
  const toCamelCase = (str) => str.replace(/-(.)/g, (_, p) => p.toUpperCase());
  const result = {};
  style.split(';').forEach((el) => {
    const [prop, value] = el.split(':');
    if (prop) {
      result[toCamelCase(prop.trim())] = value.trim();
    }
  });

  return result;
}

/**
 * Retrieve media queries from a master format node
 *
 * @param dataset  - The node dataset extended
 * @param {Array} mediaQueries
 *
 * @returns {{mediaQueries: {media: string, style: string}}}
 */
export function getMediaQueries(dataset: Object) {
  const response = [];

  const medias = Object.keys(dataset)
    .filter((key) => key.match(/media-/))
    .map((key) => dataset[key]);

  const styles = Object.keys(dataset)
    .filter((key) => key.match(/mediaStyle/))
    .map((key) => dataset[key]);

  medias.forEach((media, i) => {
    response.push({
      media,
      style: cssToJSXStyle(styles[i]),
    });
  });

  return { mediaQueries: response };
}

export const getAsJSON = (nodeParam, avoidParse = false, isDev = null, extractBody = false) => {
  if (isDev === null) {
    const ctx = useContext();
    isDev=ctx.isDev
  }
  let node = nodeParam;
  if (!avoidParse) {
    node = node.replaceAll('target=','data-target=') /** $dompurify removes target="_blank" so we do this replace and ther reinsert the target in PageBuilderComponent */
    // @TODO fix on magento. When applying template on pdp pagebuilder fields, the images src will be like {{media url=wysiwyg/image.png}} instead of the full image path
    try {
      const ctx1 = useContext();
      node = node.replaceAll('{{media url=', ctx1.$vsf.$magento.config.externalCheckout.cmsUrl + '/media/').replaceAll('}}', '')
    }catch(e){}
    node = parseClasses(node)
    
    if (process.server) {
      const { JSDOM } = require('jsdom');
      const dom = new JSDOM(node);
      node = dom.window.document;
    } else {
      node = new DOMParser().parseFromString(node as string, 'text/html');
    }

    if(node.body && extractBody)node=node.body.firstChild
  } else {
  }
  if (node.dataset?.contentType == 'block') {
    try {
      node.dataset.blockId=node.innerHTML.split(' ').find(el=>el.includes("block_id")).split('=')[1].replaceAll('"','')
  }catch(e){}
}
  const DOMContent = (avoidParse)
    || (node.dataset && !Object.keys(ALLOWED_COMPONENTS).includes(node.dataset.contentType as string))
    ? node : null;

  const content = node.body ?? node;
  content.id = `id-${Date.now().toString()}`;
  let children = [...content.querySelectorAll(`#${content.id} > [data-content-type]`)];
  /** check second level to bypass wrappers */
  if (children.length === 0) {
    children = [...content.querySelectorAll(`#${content.id} > * > [data-content-type]`)];
  }

  let dataset = { ...node.dataset } as GenericObjectType;
  dataset = { ...dataset, ...getCustomProps(node, content) };
  const object = {
    pageBuilderStyleNode: getPageBuilderStyleNode(node, avoidParse),
    tagname: node.tagName,
    children: children.map((el) => getAsJSON(el, true, isDev)),
    ...dataset,
    target:node.target,
    styles: getStyles(node),
    ...getCssClasses(content, dataset),
    DOMContent,
    ...getMediaQueries(dataset),
  };

  return object;
};
