























import { defineComponent, PropType } from '@vue/composition-api'
import accessibility from '~/utils/solepro/accessibility';  

export default defineComponent({
    name: 'SearchModal',
    props: {
        id: { type: String },
        buttonRefs: { 
            validator: (prop) => prop instanceof HTMLButtonElement || typeof prop === "object",
        }
    },
    setup(props) {
        const selectedElement = (props as any).buttonRefs;
        const overlay = document.getElementById('modal-backdrop');
        return {
            selectedElement,
            overlay
        }
    },
    mounted() {
        this.selectedElement.addEventListener("click", (e) => {
            this.openModal()
        });

        this.overlay.addEventListener("click", (e)=>{
            this.closeModal(e)
        })
        try{
            accessibility.accessibleModal(this.selectedElement);
        }catch(e){}

    },
    methods: {
        openModal() {
            // Add overlay before modal
            const modal = document.querySelector(this.selectedElement.dataset.target);
            this.overlay.classList.remove('hidden');
            document.body.classList.add('modal-open');

            // Show modal
            setTimeout(function () {
                modal.classList.add('show');
            }, 200);
            
        },
        closeModal(e) {
            const modal = document.querySelector(this.selectedElement.dataset.target);
            const iframe = modal.querySelector('iframe');
            modal.classList.remove('show');
            this.overlay.classList.add('hidden'); 
            document.body.classList.remove('modal-open');

            if (iframe) {
                const src = iframe.getAttribute('src');
                iframe.setAttribute('src', '')
                iframe.setAttribute('src', src)
            }
        }
    }
})
