import { DURATION_SPAN_MONTHLY, DURATION_SPAN_QUARTERLY, DURATION_SPAN_YEARLY, DURATION_SPAN_HALFYEARLY, DURATION_SPAN_BIENNIAL } from "./product";

export const MOBILE_MAX_WIDTH = '767.05px';
export const TABLET_MIN_WIDTH = '768px';
export const TABLET_MAX_WIDTH = '1279.05px';
export const DESKTOP_MIN_WIDTH = '1280px';

let durationLabelKey = {};
durationLabelKey[DURATION_SPAN_MONTHLY] = 'month';
durationLabelKey[DURATION_SPAN_QUARTERLY] = 'quarter';
durationLabelKey[DURATION_SPAN_HALFYEARLY] = 'semester';
durationLabelKey[DURATION_SPAN_YEARLY] = 'year';
durationLabelKey[DURATION_SPAN_BIENNIAL] = 'biennium';

export const DURATION_LABEL_KEYS = durationLabelKey;