




























import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api'
import { useContent } from '~/composables';
import HTMLContent from '~/components/solepro/HTMLContent.vue';

export default defineComponent({
    components:{
        HTMLContent
    },
    setup() {
        const { loadBlocks } = useContent();
        const helpBlock = ref(null);
        const HELP_BLOCK_IDENTIFIER = '24orepro_help_block';
        useFetch(async () => {
            const cmsBlocks = await loadBlocks({ identifiers: [HELP_BLOCK_IDENTIFIER] });
            const cmsBlockValues = Object.values(cmsBlocks);
            helpBlock.value = cmsBlockValues.find(el => el.identifier == HELP_BLOCK_IDENTIFIER)
        })
        return {
            helpBlock
        }
    },
})
