





































































import { computed, defineComponent, ref, useFetch } from '@nuxtjs/composition-api'
import Sidebar from '~/components/solepro/Sidebar.vue';
import { useCart, useConfig } from '~/composables';
import { Logger } from '~/helpers/logger';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import accessibility from '~/utils/solepro/accessibility';
import CartNotification from '~/components/solepro/CartNotification.vue';
import SearchBar from './Search/SearchBar.vue';
import { logoTrack, topBarSezioniTrack, topBarMenuTrack } from '~/helpers/trackings/24orepro/components';
import { miniCartLink } from '~/helpers/trackings/24orepro/cart';

export default defineComponent({
    components: {
        Sidebar,
        CartNotification,
        SearchBar
    },
    mounted() {
        if (document) {
            document.addEventListener("click", this.handleClickOutside);
            document.addEventListener("keydown", this.handleEscapeKey);
        }
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
        document.removeEventListener("keydown", this.handleEscapeKey);
    },
    setup() {
        const menuButton: any = ref(null);
        const sidebar = ref(null);
        const outerLinks = ref(null);
        const isMenuOpen = ref(false);
        const secondLevelOpened = ref(null);
        const categories = useCategoryStore();
        const menuCategories = ref(null);
        const { cart } = useCart();
        const {config} = useConfig();

        useFetch(async () => {
            await categories.load()
            menuCategories.value = categories.rawCategories?.items[0].children.filter(el => !!el.anchor_to_menu && !!el.include_in_menu);
        })

        const trackLogo = (href) => logoTrack(()=>location.href=href);
        const trackMiniCart = () => miniCartLink();

        return {
            trackLogo,
            trackMiniCart,
            menuButton,
            sidebar,
            outerLinks,
            isMenuOpen,
            secondLevelOpened,
            menuCategories,
            cartQty: computed(() => cart?.value?.total_quantity || 0),
            storeCode: config.value.store_code
        }
    },
    methods: {
        toggleMenu(event, onlySecondLevel = false) {
            if (event) {
                event.preventDefault();
            }
            if (onlySecondLevel) {
                this.secondLevelOpened = null;
            } else {

                this.isMenuOpen = !this.isMenuOpen;
                if (!this.isMenuOpen) {
                    this.secondLevelOpened = null;
                    this.menuButton.focus();
                } else if (window && window.innerWidth > 1024) { /** open second level on first item */
                    this.secondLevelOpened = 0; /** first item of second level */
                    topBarMenuTrack();
                }
            }
            this.updateMenuState()

        },
        handleMenuButtonKeydown(event) {
            try {
                const megaMenu = this.$refs.sidebar['$refs'].megaMenu;
                if (!megaMenu) return;
                if (this.isMenuOpen) {
                    if (event.key === "Tab") {
                        event.preventDefault();
                        if (event.shiftKey) {
                            accessibility.focusOnSection(megaMenu, false);
                        } else {
                            accessibility.focusOnSection(megaMenu, true);
                        }
                    }
                }
            } catch (e) {
                Logger.warn('Error on handleMenuButtonKeydown - megaMenu focus on section', e)
            }
        },

        handleEscapeKey(event) { //@TODO agganciare
            if (this.isMenuOpen && event.key === "Escape") {
                this.closeMenu();
                this.menuButton.focus();
            }
        },
        handleClickOutside(event) { // @TODO agganciare
            try {
                const megaMenu = this.$refs.sidebar['$refs'].megaMenu;
                if (
                    this.isMenuOpen &&
                    !this.menuButton.contains(event.target) &&
                    !megaMenu.contains(event.target) &&
                    !this.outerLinks.contains(event.target)
                ) {
                    this.closeMenu();
                }

            } catch (e) {
                Logger.warn('Error on handleClickOutside', e)
            }
        },
        updateMenuState() {
            try {
                const menuItems = this.$refs.sidebar['$refs'].megaMenu.querySelectorAll('.js-menu-item');
                menuItems.forEach((item) => {
                    const links = item.querySelectorAll("a, .js-menu-back");
                    links.forEach((link) => {
                        link.tabIndex = -1;
                    });
                });
                document.querySelector("#modal-backdrop").classList.toggle("hidden", !this.isMenuOpen);
                document.body.classList.toggle("modal-open", this.isMenuOpen);
                //this.closeOpenMenuLevels()
            } catch (e) {
                Logger.warn('Error on updateMenuState', e)
            }
        },
        closeMenu() {
            this.isMenuOpen = false;
            this.secondLevelOpened = null;
            this.updateMenuState()
        },
        openMenu(secondLevelIndex = 0) {
            this.isMenuOpen = true;
            this.secondLevelOpened = secondLevelIndex;
            this.updateMenuState()
            topBarMenuTrack();
        },
        closeOpenMenuLevels() {
            // @TODO check if can be optimized in vue way
            try {
                const megaMenu = this.$refs.sidebar['$refs'].megaMenu;
                const megaMenuLevels = megaMenu.querySelectorAll('.js-menu-item');
                const openLevels = Array.from(megaMenuLevels).filter((item: HTMLElement) => {
                    return item.classList.contains("shown");
                });

                if (openLevels.length === 0) {
                    return;
                }

                openLevels.forEach((item: HTMLElement) => {
                    item.classList.remove("shown");
                    item.querySelector(".js-menu-item-btn").setAttribute("aria-expanded", 'false');
                    const links = item.querySelectorAll("a, .js-menu-back");
                    links.forEach((link: HTMLElement) => {
                        link.tabIndex = -1;
                    });
                });

                //megaMenu.classList.add("overflow-auto");
            } catch (e) {
                Logger.warn('Error on closeOpenMenuLevels', e)

            }
        },
        handleHeaderLinkClick(categoryUid, name) {
            const index = Array.from(this.$refs.sidebar['menuCategories']).findIndex(cat => cat['uid'] == categoryUid);
            this.openMenu(index)
            topBarSezioniTrack(name);
        }

    }
})
