export const FOOTER_LINKS = {
  footer: {
    title: 'IL GRUPPO',
    items: [
      {
        title: 'Gruppo 24 ORE',
        link: 'https://www.gruppo24ore.ilsole24ore.com/it',
        external: true
      },
      {
        title: 'Il Sole 24 ORE',
        link: 'https://www.ilsole24ore.com/',
        external: true
      },
      {
        title: 'Radio24',
        link: 'http://www.radio24.ilsole24ore.com/',
        external: true
      },
      {
        title: 'Radiocor',
        link: 'https://www.ilsole24ore.com/sez/radiocor',
        external: true
      },
      {
        title: '24 ORE Cultura',
        link: 'http://www.24orecultura.com/',
        external: true
      },
      {
        title: '24 ORE System',
        link: 'https://24oresystem.ilsole24ore.com/it/',
        external: true
      }
    ]
  },
  business: [{
    title: "Dati societari",
    link: "https://www.gruppo24ore.ilsole24ore.com/it-it/institutional/dati-societari",
    external: true
  }],
  bottom: [
    {
      title: "Termini e condizioni",
      link: "https://ecommerce-static.ilsole24ore.com/media/Terms&Conditions_v1.pdf",
      external: true
    },
    {
      title: "Privacy Policy",
      link: "https://du.ilsole24ore.com/utenti/privacyfiles/privacy_policy.html",
      external: true
    },
    {
      title: "Cookie Policy",
      link: "https://du.ilsole24ore.com/utenti/privacyfiles/informativa_cookies.html",
      external: true
    },
    {
      title: "Dichiarazione di accessibilità",
      link: "https://st.ilsole24ore.com/st/accessibilita/",
      external: true
    }
  ],
  bottomRight: [
    {
      title: "Per la pubblicità sul sito: <a class='text-2xs leading-120 button--link' href='{link}' target='_blank'><span>Websystem</span></a>",
      interpolate: true,
      external: true,
      link:"https://24oresystem.ilsole24ore.com/it/brand-connect/branded-content-digital/"
    },
    /*{
      title:"Informativa post telefonata servizio clienti"
    },*/
    {
      title: "L’utilizzo dei dati personali da parte di GOCardless è regolato nell’<a class='text-2xs leading-120 button--link' target='_blank' href='{link}'><span>informativa sulla privacy</span></a>",
      external: true,
      interpolate: true,
      link: "https://gocardless.com/privacy/"
    }
  ]

}