





/* eslint-disable */
// @ts-nocheck
import { defineComponent, useContext } from '@nuxtjs/composition-api';
import { getAsJSON } from '~/utils/solepro/CmsComponentAggregator';
//import { useMemoize } from '@vueuse/core'
import PageBuilderComponent from '~/mixins/solepro/PageBuilderComponent.vue';
import { appendStylesFromText } from '~/utils/solepro/functions';


export default defineComponent({
  name: 'HTMLContent',
  components:{
    PageBuilderComponent
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    content: {
      type: String,
      default: '',
    },
    extractBody:{
      type: Boolean,
      default:false
    }
    
  },
  setup(props){
    const { content } = props;
    //const getContentTree = useMemoize(()=>getAsJSON(content)); /**@TODO find how usememoize can work. Now nuxtconfig aliases are not working for vueuse/core :( ) */
    const getContentTree = ()=>getAsJSON(content, false, null, props.extractBody);
    const contentTree = getContentTree()
    if(contentTree.pageBuilderStyleNode) appendStylesFromText(contentTree.pageBuilderStyleNode)

    return {
      getContentTree,
      contentTree
    }
  },
 /* beforeDestroy(){
    this.getContentTree.clear(); /** Avoid memory leak for storing all the content tree * /
  }*/
});
