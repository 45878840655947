export const PRODOTTI = 'prodotti';


/**
  * @TDOO remove when busness inserts products on theeese categories
  */
export const CATEGORIES_TO_REDIRECT = {
  'prodotti/workshop-eventi': {url: 'https://24oreworkshop.ilsole24ore.com/', target:'_blank'},
  'prodotti/networking': {url: '/24orepro/networking'},
  'prodotti/formazione': {url: '/24orepro/formazione'},
  'prodotti/certificazioni': {url: '/24orepro/certificazioni'},
}