





import { defineComponent } from '@nuxtjs/composition-api'
import { AccordionItemType, GenericObjectType } from '~/utils/types';

export default defineComponent({
    props:{
        classes: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            name:'AccordionGroup',//DO NOT REMOVE
            childrenAccordion: []
        }
    },
    mounted() {
        this.$on("toggle", this.toggleHandler);

    },
    methods: {
        /**
         * This manages the opening of the right Accordion and the closing of others
         */
        toggleHandler(slotId) {
            const childrenAccordion = this.$children.filter(el => el['name'] = 'Accordion') as GenericObjectType[];
            childrenAccordion.forEach(child=>{
                if(child._uid==slotId){
                    child.isOpen = !child.isOpen
                }else{
                    child.isOpen=false;
                }
            });
        }
    }
})
