/**
 * map of content.contenType as key and the import path (from ~/components/solepro/) of the  component to render as value
 */
export const ALLOWED_COMPONENTS = {
  row: () => import('~/components/solepro/PageBuilder/Row.vue'),
  'column-group': () => import('~/components/solepro/PageBuilder/ColumnGroup.vue'),
  'column-line': () => import('~/components/solepro/PageBuilder/ColumnLine.vue'),
  'column': () => import('~/components/solepro/PageBuilder/Column.vue'),
  tabs: () => import('~/components/solepro/PageBuilder/Tabber.vue'),
  'tab-item': true, // mapping just to make an object to use inside Tabber
  'video': () => import('~/components/solepro/PageBuilder/Video.vue'),
  'banner': () => import('~/components/solepro/PageBuilder/Banner.vue'),
  'slider': () => import('~/components/solepro/PageBuilder/Slider.vue'),
  // 'slide': () => import('~/components/solepro/PageBuilder/Slide.vue'),
  'evidences': () => import('~/components/solepro/PageBuilder/Evidences.vue'),
  'tutorial': () => import('~/components/solepro/PageBuilder/Tutorial.vue'),
  // 'evidence': () => import('~/components/solepro/PageBuilder/Evidence.vue'),
  'products': () => import('~/components/solepro/PageBuilder/Products.vue'),
  'block': () => import('~/components/solepro/PageBuilder/Block.vue'),
  'image': () => import('~/components/solepro/PageBuilder/Image.vue'),
  'buttons': () => import('~/components/solepro/PageBuilder/Buttons.vue'),
  'accordion': () => import('~/components/solepro/PageBuilder/Accordion.vue'),
  'plp-product-box': ()=>import('~/components/solepro/PageBuilder/PLPProductBoxWrapper.vue')
};



export const getComponent = (componentName, cssClasses=[]) => { 
  if (cssClasses.includes('section-tutorials')) {
    return ALLOWED_COMPONENTS['tutorial']
  } else if (cssClasses.includes('plp-product-box')) {
    return ALLOWED_COMPONENTS['plp-product-box']
  }
  return (componentName && !!ALLOWED_COMPONENTS[componentName] && typeof ALLOWED_COMPONENTS[componentName] != 'boolean' ? ALLOWED_COMPONENTS[componentName] : 'div');
}



